import * as React from 'react';
import type { ErrorInfo } from 'react';

import * as Sentry from '@sentry/react';

import a11yKeyPress from 'js/lib/a11yKeyPress';
import appInfo from 'js/lib/appInfo';
import { isUserRightToLeft } from 'js/lib/language';

import { Link } from '@coursera/cds-core';

import Error500 from 'bundles/page/components/Error500';

import ChromeLogo from 'images/browser-logos/ChromeLogo.png';
import EdgeLogo from 'images/browser-logos/EdgeLogo.png';
import FirefoxLogo from 'images/browser-logos/FirefoxLogo.png';

import _t from 'i18n!nls/page';

const DismissKey = 'IE.dismissedAt';
const DismissDuration = 1000 * 60 * 60 * 8 - 1000 * 60; // 7h59m

const fontFamily = '"Source Sans Pro", OpenSans, Arial, sans-serif';

const InlineStyles = {
  Container: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#F3F8FF',
    borderBottom: '1px solid #929599',
    padding: '16px 48px',
    zIndex: 999999, // above everything.
  },
  Heading: {
    fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
    color: '#1F1F1F',
    margin: '16px 0',
  },
  Paragraph: {
    fontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#1F1F1F',
    margin: '16px 0',
  },
  List: {
    fontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#1F1F1F',
    listStyle: 'none',
    margin: '16px 0',
    padding: 0,
  },
  ListItem: {
    display: 'inline-block' as const,
    marginRight: 16,
  },
  ListItemRTL: {
    display: 'inline-block' as const,
    marginLeft: 16,
  },
  Link: {
    color: '#0056D2',
    textDecoration: 'underline' as const,
  },
  Icon: {
    marginRight: 4,
    verticalAlign: 'text-bottom' as const,
  },
  IconRTL: {
    marginLeft: 4,
    verticalAlign: 'text-bottom' as const,
  },
  Dismiss: {
    fontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    color: '#0056D2',
    position: 'absolute' as const,
    top: 36,
    right: 48,
  },
  DismissRTL: {
    fontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    color: '#0056D2',
    position: 'absolute' as const,
    top: 36,
    left: 48,
  },
};

type PropsFromCaller = {
  children?: JSX.Element | null;
};

type PropsForGuard = PropsFromCaller & {
  Component: typeof InternetExplorerBannerWithDismiss | typeof InternetExplorerBannerWithErrorBoundary;
};

type PropsFromDismiss = {
  onDismiss?: () => void;
};

type StateForDismiss = {
  isMounted: boolean;
  isDismissed: boolean;
};

type StateForErrorBoundary = {
  hasError: boolean;
};

export const InternetExplorerBanner = ({ onDismiss }: PropsFromDismiss) => {
  return (
    <div
      role="dialog"
      aria-modal="false"
      aria-labelledby="InternetExplorerBanner-Heading"
      aria-describedby="InternetExplorerBanner-Description"
      style={InlineStyles.Container}
    >
      <h1 id="InternetExplorerBanner-Heading" style={InlineStyles.Heading}>
        {_t('Browser not supported')}
      </h1>
      {onDismiss && (
        <div
          role="button"
          tabIndex={0}
          onClick={onDismiss}
          onKeyDown={(event) => a11yKeyPress(event, onDismiss)}
          aria-label={_t('Dismiss browser not supported warning')}
          style={isUserRightToLeft() ? InlineStyles.DismissRTL : InlineStyles.Dismiss}
        >
          {_t('Dismiss')}
        </div>
      )}
      <p id="InternetExplorerBanner-Description" style={InlineStyles.Paragraph}>
        {_t(
          'Sorry, you’re using a browser that is no longer supported. You might be unable to access content on this or any other page.'
        )}
      </p>
      <p style={InlineStyles.Paragraph}>{_t('We recommend one of the following options to access Coursera:')}</p>
      <ul style={InlineStyles.List}>
        <li style={isUserRightToLeft() ? InlineStyles.ListItemRTL : InlineStyles.ListItem}>
          <Link
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
            style={InlineStyles.Link}
          >
            <img
              src={ChromeLogo}
              width="24"
              height="24"
              alt=""
              style={isUserRightToLeft() ? InlineStyles.IconRTL : InlineStyles.Icon}
            />
            Google Chrome
          </Link>
        </li>
        <li style={isUserRightToLeft() ? InlineStyles.ListItemRTL : InlineStyles.ListItem}>
          <Link
            href="https://www.microsoft.com/edge"
            target="_blank"
            rel="noopener noreferrer"
            style={InlineStyles.Link}
          >
            <img
              src={EdgeLogo}
              width="24"
              height="24"
              alt=""
              style={isUserRightToLeft() ? InlineStyles.IconRTL : InlineStyles.Icon}
            />
            Microsoft Edge
          </Link>
        </li>
        <li style={isUserRightToLeft() ? InlineStyles.ListItemRTL : InlineStyles.ListItem}>
          <Link
            href="https://www.mozilla.org/firefox/new/"
            target="_blank"
            rel="noopener noreferrer"
            style={InlineStyles.Link}
          >
            <img
              src={FirefoxLogo}
              width="24"
              height="24"
              alt=""
              style={isUserRightToLeft() ? InlineStyles.IconRTL : InlineStyles.Icon}
            />
            Mozilla Firefox
          </Link>
        </li>
      </ul>
      <p style={InlineStyles.Paragraph}>
        {_t('Have questions?')}{' '}
        <Link
          href="https://learners.coursera.help/hc/articles/209818543-Recommended-browsers-and-devices"
          target="_blank"
          rel="noopener noreferrer"
          style={InlineStyles.Link}
        >
          {_t('Learn more in our learner help center.')}
        </Link>
      </p>
    </div>
  );
};

export class InternetExplorerBannerWithDismiss extends React.Component<PropsFromCaller, StateForDismiss> {
  state = {
    isMounted: false,
    isDismissed: false,
  };

  componentDidMount() {
    let isDismissed: boolean;
    try {
      const dismissedAt = sessionStorage.getItem(DismissKey);
      isDismissed = dismissedAt ? +dismissedAt > Date.now() - DismissDuration : false;
    } catch {
      isDismissed = false;
    }

    this.setState({ isMounted: true, isDismissed });
  }

  onDismiss = () => {
    try {
      sessionStorage.setItem(DismissKey, String(Date.now()));
    } catch {
      // do nothing
    }

    this.setState({ isDismissed: true });
  };

  render() {
    const { children } = this.props;
    const { isMounted, isDismissed } = this.state;

    return (
      <>
        {!isDismissed && <InternetExplorerBanner onDismiss={isMounted ? this.onDismiss : undefined} />}
        {children}
      </>
    );
  }
}

export class InternetExplorerBannerWithErrorBoundary extends React.Component<PropsFromCaller, StateForErrorBoundary> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, {
      extra: { errorInfo },
      tags: { source: 'InternetExplorerBannerWithErrorBoundary' },
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return <InternetExplorerBannerWithDismiss>{hasError ? <Error500 /> : children}</InternetExplorerBannerWithDismiss>;
  }
}

export const InternetExplorerBannerWithGuard = ({ children = null, Component }: PropsForGuard) => {
  const shouldRenderBanner = appInfo.get().userAgent?.browser?.name === 'IE';

  if (!shouldRenderBanner) {
    return children;
  }

  return <Component>{children}</Component>;
};

export default InternetExplorerBannerWithGuard;

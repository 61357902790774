import * as React from 'react';
import type { PlainRoute, RenderProps, Router, RouterState } from 'react-router';
import { RouterContext, createMemoryHistory, match, useRouterHistory } from 'react-router';
import type { MatchArgs } from 'react-router/lib/match';

import type { ApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { createHistory } from 'history';
import type { Location } from 'history';
import { merge } from 'lodash';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import useNamedRoutes from 'vendor/cnpm/use-named-routes.v0-3/index';

import loggerSingleton from 'js/app/loggerSingleton';

import type { PageViewOptions } from '@coursera/event-pulse/sdk';
import { trackPageView } from '@coursera/event-pulse/sdk';

import { recordPageView } from 'bundles/page/lib/eventing';

const extendRenderProps = (renderProps: $TSFixMe) => {
  if (renderProps) {
    const { params, location, router, routes } = renderProps;

    // While params, location, and route will still be available as `props` on the Route component,
    // we're also going to house them on the RouterContext to make it easier to just specify
    // one contextType (router, which is an object) instead of separate ones for each prop.
    // This is a future improvement in [RR v3.0](https://github.com/reactjs/react-router/issues/3325)
    Object.assign(router, {
      params,
      location,
      routes,
    });
  }

  return renderProps;
};

type LocationStub = {
  hostname: string | undefined;
  protocol: string;
};

const addHostnameAndProtocol = (renderProps: $TSFixMe, { hostname, protocol }: LocationStub) => {
  if (!renderProps) return renderProps;
  const { location } = renderProps;
  location.hostname = hostname;
  location.protocol = protocol;
  return Object.assign({}, renderProps, { location });
};

const getRouterContext = (renderProps: $TSFixMe) => (
  <RouterContext {...extendRenderProps(addHostnameAndProtocol(renderProps, window.location))} />
);

function normalizeMatches(routes: PlainRoute[]) {
  const path = routes
    // Extract path of each route, some of which may be undefined
    .map((route) => route.path)
    // Clean undefined paths
    .filter(Boolean)
    // Join all paths together
    .join('/');

  // Prefix with a slash and remove any double slashes
  return `/${path}`.replace(/\/+/g, '/');
}

export const setupClient = (matchOptions: MatchArgs & { apolloClient?: ApolloClient<{}> }): Promise<RenderProps> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history: $TSFixMe = useNamedRoutes(useRouterHistory(createHistory))({
    routes: matchOptions.routes,
  });

  let previousLocation: { pathname?: string; search?: string } = {};
  history.listen((location: Location) => {
    const currentLocation = location;
    match(
      {
        ...matchOptions,
        history,
      },
      (error, redirectLocation, renderProps: RenderProps) => {
        const matchedRoutes = renderProps?.routes || [];
        // Extract the path of all routes (so nested routes show parent paths) and remove empty ones (sometimes the root route is empty)
        const routeId = normalizeMatches(matchedRoutes);

        const trackingDataFactories: ((
          params: RouterState['params'],
          location: Location
        ) => Partial<PageViewOptions>)[] = matchedRoutes.map((route) => route.handle?.tracking.data).filter(Boolean);
        const routeDefaultSegment = matchedRoutes
          .map((route) => route.handle?.tracking.defaultSegment)
          .filter(Boolean)
          .at(0) as 'consumer' | 'enterprise' | undefined;
        const routeOptedInToSegment = matchedRoutes.some((route) => route.handle?.tracking);
        const pageSegmentFromRoute = matchedRoutes
          .map((route) => route.handle?.tracking.segment)
          .filter(Boolean)
          .at(0) as 'consumer' | 'enterprise' | undefined;
        const mergedTrackedData = merge(
          {},
          ...trackingDataFactories.map((parser) => parser(renderProps.params, location)),
          {
            page: {
              route: routeId,
              segment: pageSegmentFromRoute,
            },
          }
        );

        if (
          previousLocation.pathname !== currentLocation.pathname ||
          previousLocation.search !== currentLocation.search
        ) {
          const isSinglePageAppNavigation = Object.keys(previousLocation).length !== 0;
          // V2 Page view
          recordPageView({
            locationAction: location.action,
            isSinglePageAppNavigation,
          });

          try {
            // V3 page view
            if (!redirectLocation) {
              trackPageView(mergedTrackedData, {
                defaultSegment: routeDefaultSegment,
                graphqlClient: matchOptions.apolloClient,
                segmentOptIn: routeOptedInToSegment,
              });
            }
          } catch (error) {
            // Defensive code to ensure any errors don't affect application code
            if (typeof window === 'undefined' || typeof document === 'undefined') {
              // We can't use sentry in SSR so we log instead although the function shouldn't run in SSR anyway
              loggerSingleton.error('Failed to track page view in EventPulse', error);
            } else {
              Sentry.captureException('Failed to track page view in EventPulse', error);
            }
          }
        }
        previousLocation = currentLocation;
      }
    );
  });

  // We use `match` in order to resolve all asynchronous routes defined by React Router.
  // This is in order to ensure that we can mount CSR applications onto SSR pages properly.
  // See https://github.com/reactjs/react-router/blob/348947e/docs/guides/ServerRendering.md#async-routes

  return new Promise((resolve, reject) => {
    match(
      {
        ...matchOptions,
        history,
      },
      (error?, redirectLocation?, renderProps?) => {
        if (redirectLocation) {
          return resolve(
            setupClient({
              ...matchOptions,
              location: redirectLocation,
            })
          );
        } else {
          if (error) {
            return reject(error);
          }
          if (!renderProps) {
            const err = new Error('no route found matching the given location');
            Sentry.captureException(err);
            return reject(err);
          }
          return resolve(
            Object.assign({}, renderProps, {
              render: getRouterContext,
            })
          );
        }
      }
    );
  });
};

type MatchOptions = {
  routes: Router.RouteConfig;
  location?: string;
  hostname?: string;
  protocol?: string;
};

export function setupServer(
  matchOptions: MatchOptions
): Promise<{ redirectLocation: Omit<Location, 'hash'>; renderProps: RenderProps }>;
export function setupServer(
  matchOptions: MatchOptions,
  matchCb: (error: Error | undefined, redirectLocation: Omit<Location, 'hash'>, renderProps: RenderProps) => void
): void;
export function setupServer(
  matchOptions: MatchOptions,
  matchCb?: (error: Error | undefined, redirectLocation: Omit<Location, 'hash'>, renderProps: RenderProps) => void
): void | Promise<{ redirectLocation: Omit<Location, 'hash'>; renderProps: RenderProps }> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history: $TSFixMe = useNamedRoutes(createMemoryHistory)({
    routes: matchOptions.routes,
  });
  const urlInfo = {
    hostname: matchOptions.hostname,
    protocol: `${matchOptions.protocol || 'https'}`,
  };

  if (matchCb) {
    return match(
      {
        ...matchOptions,
        history,
      },
      (error, redirectLocation, renderProps) => {
        matchCb(error, redirectLocation, extendRenderProps(addHostnameAndProtocol(renderProps, urlInfo)));
      }
    );
  } else {
    return new Promise((resolve, reject) => {
      match(
        {
          ...matchOptions,
          history,
        },
        (error, redirectLocation, renderProps) => {
          if (error) {
            reject(error);
          } else {
            resolve({
              redirectLocation,
              renderProps: extendRenderProps(addHostnameAndProtocol(renderProps, urlInfo)),
            });
          }
        }
      );
    });
  }
}
